import React, {useState, useEffect} from "react";
import { graphql } from "gatsby";
import { Redirect } from "@reach/router";

import ModAnimation from "../../components/Chronology/Modules/ModAnimation"
import { Flex, Box, Text } from '@sqymagma/elements'

const IndexPage = ({ data }) => {
  const [lastWeek, setLastWeek] = useState(null)
  useEffect(() => setLastWeek(data.allSitePage.edges[0].node.path),[data.allSitePage.edges])

  return (
   <>
    <LoaderScreen></LoaderScreen>
    {lastWeek && <Box height="100vh"><Redirect noThrow to={lastWeek}/></Box>}
   </>
    )
};

export const pageQuery = graphql`
  query {
    allSitePage(
      filter: { id: { regex: "/semana-[0-9]+/$/" } }
      sort: { fields: path, order: DESC }
    ) {
      edges {
        node {
          path
        }
      }
    }
  }
`;

export default IndexPage;

const LoaderScreen = _ => (
  <Flex alignItems="center" justifyContent="center" flexDirection="column"
    zIndex="9999999" bg="brand05" 
    position="fixed" top="0" right="0" left="0" bottom="0" width="100vw" height="100vh">
      <Flex width="120px" height="120px" alignItems="center" justifyContent="center">
        <ModAnimation svg="/images/anim/logo_ani_white.svg"/>
      </Flex>
      <Text color="text01" textStyle="s" textAlign="center">Cargando...</Text>
  </Flex>
)